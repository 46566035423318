import * as React from "react";
import { FC, memo, useMemo } from "react";
import { useDispatch } from "react-redux";
import { ListGroup } from "react-bootstrap";

import { Lane } from "../../../map-interface.d";
import { setSpeedLimit } from "../../../store/slices/mapStructs";
import {
  RemoveLaneButtonStyled,
  SpeedLimitContainerStyled,
  SpeedLimitInputStyled,
} from "../styles";

import { PropNameStyled, PropLineStyled } from "./styles";

type ExpandedLaneProps = {
  lane: Lane;
  removeLane: (laneId: number) => void;
};

export const ExpandedLane: FC<ExpandedLaneProps> = memo(
  ({ lane, removeLane }) => {
    const dispatch = useDispatch();
    const {
      lane_id,
      start_line_id,
      termination_line_id,
      left_boundary_line_id,
      right_boundary_line_id,
      speed_limit,
    } = lane;

    const propsList = useMemo(
      () =>
        Object.keys(lane).map((prop) => {
          if (prop === "speed_limit") return null;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const laneProp = `${lane[prop]}`;

          return (
            <PropLineStyled key={prop} style={{ textTransform: "capitalize" }}>
              <PropNameStyled>{prop.replace(/_/g, " ")}:</PropNameStyled>
              {laneProp}
            </PropLineStyled>
          );
        }),
      [
        start_line_id,
        termination_line_id,
        left_boundary_line_id,
        right_boundary_line_id,
        speed_limit,
      ]
    );

    const speedLimitInput = useMemo(() => {
      return (
        <PropLineStyled>
          <PropNameStyled>Speed Limit:</PropNameStyled>
          <SpeedLimitInputStyled
            id={String(lane_id)}
            value={speed_limit}
            onChange={(e: any) => {
              dispatch(
                setSpeedLimit({
                  laneId: lane_id,
                  speedLimit: Number(e?.currentTarget?.value) || undefined,
                })
              );
            }}
          />
        </PropLineStyled>
      );
    }, [speed_limit]);

    return (
      <ListGroup as="ul">
        {propsList}
        {speedLimitInput}
        <SpeedLimitContainerStyled>
          <RemoveLaneButtonStyled onClick={() => removeLane(lane_id)}>
            Remove
          </RemoveLaneButtonStyled>
        </SpeedLimitContainerStyled>
      </ListGroup>
    );
  }
);
